import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";
import ImageColumns from "../components/image-columns";
import Image from "../components/image";
import LightboxContainer from "../components/lightbox-container";

const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false })

export const segments = graphql`
  query ClientsQuery
  {
    allSegmentsJson {
      edges
      {
        node
        {
          colSize
          className
          imageName
          imageAlt
          title
          subtitle
        }
      }
    },
    allClientsJson {
      edges
      {
        node
        {
          colSize
          className
          imageName
          imagePath {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          text
          link
        }
      }
    }
  }
`;

const IndexPage = ({ intl, data }) => {
  return <Layout>
    <SEO title={intl.formatMessage({id: "Clients"})} description=""/>

    <Banner 
        className="banner" 
        filename="clients-banner.png"
        title={intl.formatMessage({id: "Find out how we help our customers"})} 
        subtitle={intl.formatMessage({id: "We will be happy to meet with you to better understand how we can help you"})}
    />

    <Section title={intl.formatMessage({id: "Who hires our products?"})}>
      <ImageColumns columns={data.allSegmentsJson.edges} className="services" />
    </Section>

    <Section title={intl.formatMessage({id: "Get to know our client's platforms"})}>
      <LightboxContainer columns={data.allClientsJson.edges} className="clients-platforms" />
    </Section>
    
    <Section>
      <OwlCarousel
        className="owl-theme"
        loop={true}
        dots={false}
        items={6}
        nav={true}
        autoplay={true}
        autoplayTimeout={2000}
      >
        <div className="client-logo"><Image filename="client-logo-1.png"/></div>
        <div className="client-logo"><Image filename="client-logo-2.png"/></div>
        <div className="client-logo"><Image filename="client-logo-3.png"/></div>
        <div className="client-logo"><Image filename="client-logo-4.png"/></div>
        <div className="client-logo"><Image filename="client-logo-5.png"/></div>
        <div className="client-logo"><Image filename="client-logo-6.png"/></div>
        <div className="client-logo"><Image filename="client-logo-7.png"/></div>
        <div className="client-logo"><Image filename="client-logo-8.png"/></div>
        <div className="client-logo"><Image filename="client-logo-9.png"/></div>
        <div className="client-logo"><Image filename="client-logo-10.png"/></div>
        <div className="client-logo"><Image filename="client-logo-11.png"/></div>
        <div className="client-logo"><Image filename="client-logo-12.png"/></div>
        <div className="client-logo"><Image filename="client-logo-13.png"/></div>
        <div className="client-logo"><Image filename="client-logo-14.png"/></div>
        <div className="client-logo"><Image filename="client-logo-15.png"/></div>
        <div className="client-logo"><Image filename="client-logo-16.png"/></div>
        <div className="client-logo"><Image filename="client-logo-17.png"/></div>
        <div className="client-logo"><Image filename="client-logo-18.png"/></div>
        <div className="client-logo"><Image filename="client-logo-19.png"/></div>
        <div className="client-logo"><Image filename="client-logo-20.png"/></div>
        <div className="client-logo"><Image filename="client-logo-21.png"/></div>
        <div className="client-logo"><Image filename="client-logo-22.png"/></div>
      </OwlCarousel>
    </Section>
  </Layout>
}

export default injectIntl(IndexPage);